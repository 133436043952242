<template>
  <div class="home-frame">
    <mdb-carousel
        :items="items"
        :interval="5000"
        indicators
        indicatorsColor="blue"
    ></mdb-carousel>
    <div class="title-box">
      <div class="title-real">
        <h1>치료의 첫 시작,<br/>
          올바른 평가와 함께하세요!
        </h1>
        <h5>한국 재활 임상평가 데이터베이스</h5>
      </div>
      <div class="title-opacity">
        <h1>치료의 첫 시작,<br/>
          올바른 평가와 함께하세요!
        </h1>
        <h5>한국 재활 임상평가 데이터베이스</h5>
      </div>
    </div>
    <div class="btn-container">
      <router-link to="/therapist/login">
        <div class="btn-big-label">
          <img src="@/assets/images/IA/icon_therapist.png" alt="therapist" class="icon">
          <div class="label-text">
            <h2>치료사 메뉴 이용하기</h2>
            <p>치료차트 및 평가도구 관리</p>
          </div>
          <img src="@/assets/images/IA/btn_next.png" alt="patient" class="arrow">
        </div>
      </router-link>
      <router-link to="/user/login">
        <div class="btn-big-label btn-label-color">
          <img src="@/assets/images/IA/icon_patient.png" alt="patient" class="icon">
          <div class="label-text">
            <h2>환자 메뉴 이용하기</h2>
            <p>재활 평가내용 조회</p>
          </div>
          <img src="@/assets/images/IA/btn_next.png" alt="patient" class="arrow">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mdbCarousel} from "mdbvue";
import bg_1 from '@/assets/images/IA/slider_img_1.png';
import bg_2 from '@/assets/images/IA/slider_img_2.png';


export default {
  name: 'Home',
  components: {
    mdbCarousel
  },
  data() {
    return {
      bg_1_src: bg_1,
      items: [
        {
          img: true,
          src: bg_1,
        },
        {
          img: true,
          src: bg_2,
        },
      ]
    };
  },
};
</script>
<style scoped>
.home-frame {
  position: relative;
  max-width: 1920px;
  max-height: 892px;
  margin: 0 auto;

}

.cover {
  position: absolute;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.title-box {
  position: absolute;
  justify-content: center;
  top: 235px;
  width: 100%;
  z-index: 50;
  display: flex;
  flex-direction: row;
}

.title-real {
  width: 461px;
  display: flex;
  flex-direction: column;
}

.title-opacity {
  width: 461px;
  display: flex;
  opacity: 0;
  flex-direction: column;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #FFFFFF;
  margin-bottom: 16px;
}

h5 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.btn-container {
  position: absolute;
  justify-content: center;
  bottom: 0;
  height: 118px;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
}

.btn-big-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px;
  width: 461px;
  height: 118px;
  background: rgba(0, 105, 202, 0.9);
  cursor: pointer;
}

.icon {
  width: 73px;
  height: 73px;
}

.arrow {
  width: 50px;
  height: 50.5px;
  margin-left: auto;
}

.btn-big-label h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin: 0;
}

.label-text {
  margin: 0 25px;
}

.btn-big-label p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: 0;
}

.btn-label-color {
  background: rgba(123, 182, 244, 0.9);
}

/deep/ .carousel-indicators {
  top: 274px !important;
  bottom: unset;
  left: unset;
  justify-content: end;
  margin-right: 109px;
  z-index: 100;
  flex-direction: column;
}

/deep/ .carousel-indicators li {
  background-color: #FFFFFF !important;
  width: 8px;
  height: 8px;
  margin-right: 0;
  margin-left: 0;
  border: 0;
  margin-top: 10px;
  border-radius: 20px;
}

/deep/ .carousel-indicators .active {
  background-color: #7BB6F4 !important;
  height: 16px;
  border-radius: 20px;
}


.home-title {
  position: fixed;
  width: 922px;
  height: 134px;
  top: 303px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%);
}


</style>
